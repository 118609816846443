<script setup lang="ts">
import { CmsBlockImageSlider } from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsBlockImageSlider;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const cmsContent = getSlotContent("imageSlider");
</script>

<template>
  <div class="s-gutter">
    <div class="c-width-narrow">
      <CmsGenericElement :content="cmsContent" />
    </div>
  </div>
</template>
